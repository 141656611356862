import { experimentManager } from '../../services/ExperimentManager/ExperimentManager'

export const MAIN_PHOTO_ID = 'front'

const DEFAULT_SCALE_V2 = 1.1
const DEFAULT_SCALE = 1.16

export function getDefaultWallScale() {
  const isGalleryWallv2 = experimentManager.isEnabled('gallery-walls-v2')
  return isGalleryWallv2 ? DEFAULT_SCALE_V2 : DEFAULT_SCALE
}

export const ENTRY_POINT_SOURCE = {
  MENU: 'Menu',
  FOOTER: 'Footer',
  BACK: 'Back',
  SNACKBAR: 'Snackbar',
  CAMPAIGN: 'Campaign',
  DIRECT: 'Direct',
}

// The bottom height is according to a fixed asset ratio the designer has decided on
const BOTTOM_SIDE_HEIGHT_V2 = 79

export function getSmallScreenRenderHeight() {
  const isGalleryWallV2 = experimentManager.isEnabled('gallery-walls-v2')
  return isGalleryWallV2
    ? window.innerHeight - BOTTOM_SIDE_HEIGHT_V2
    : window.innerHeight
}
