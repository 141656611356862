import { type FunctionComponent, type SVGProps } from 'react'
import {
  CAREERS_URL,
  FACEBOOK_URL,
  GIFT_CARD,
  IMPRESSUM_URL,
  INSTAGRAM_URL,
  YOUTUBE_URL,
  TIKTOK_URL,
  PINTEREST_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
  BLOG_URL,
  ACCESSIBILITY_URL,
  BUSINESS_PAGE_URL,
  COOKIE_POLICY_URL,
} from '../../config/config'

import { ReactComponent as FBLogo } from 'images/homePage/socials/fb.svg'
import { ReactComponent as InstaLogo } from 'images/homePage/socials/insta.svg'
import { ReactComponent as YTLogo } from 'images/homePage/socials/yt.svg'
import { ReactComponent as TiktokLogo } from 'images/homePage/socials/tiktok.svg'
import { ReactComponent as PinterestLogo } from 'images/homePage/socials/pinterest.svg'

export const TEXTS = {
  BUSINESS: 'general.menu.for_business',
  BLOG: 'pitch.footer.blog',
  CAREERS: 'pitch.footer.careers',
  JOIN_US: 'pitch.footer.join_us',
  FRAME_YOUR_PHOTOS: 'general.menu.frame_your_photos',
  PHOTO_BOOKS: 'product_selection.photo_books_name',
  GIFT: 'general.menu.gift_card_v2',
  GALLERY_WALLS: 'pitch.footer.gallery_walls',
  MIXTILES_ART: 'general.menu.mixtiles_art',
  ACCESSIBILITY: 'pitch.footer.accessibility',
  ACCESSIBILITY_BUTTON: 'general.menu.accessibility_button',
  PRIVACY: 'pitch.footer.privacy',
  COOKIE: 'pitch.footer.cookie_policy',
  IMPRESSUM: 'pitch.footer.impressum',
  TERMS: 'pitch.footer.terms_of_use',
  ABOUT: 'pitch.footer.about',
  PRODUCTS: 'pitch.footer.products',
  RESOURCES: 'pitch.footer.resources',
  FOLLOW_US: 'pitch.footer.follow_us',
  INSTAGRAM: 'pitch.footer.instagram',
  FACEBOOK: 'pitch.footer.facebook',
  TWITTER: 'pitch.footer.twitter',
  CHANGE_LANGUAGE: 'general.languages.change_language',
  COOKIES: 'homepage.cookies_choices',
}

type LogoComponent = FunctionComponent<SVGProps<SVGSVGElement>>
export const socials: {
  Logo: LogoComponent
  href: string
  alt: string
  name: string
}[] = [
  {
    name: 'Instagram',
    Logo: InstaLogo,
    href: INSTAGRAM_URL,
    alt: 'Mixtiles Instagram',
  },
  {
    name: 'Facebook',
    Logo: FBLogo,
    href: FACEBOOK_URL,
    alt: 'Mixtiles Facebook',
  },
  {
    name: 'Tiktok',
    Logo: TiktokLogo,
    href: TIKTOK_URL,
    alt: 'Mixtiles Tiktok',
  },
  {
    name: 'Pinterest',
    Logo: PinterestLogo,
    href: PINTEREST_URL,
    alt: 'Mixtiles Pinterest',
  },
  {
    name: 'Youtube',
    Logo: YTLogo,
    href: YOUTUBE_URL,
    alt: 'Mixtiles Youtube',
  },
]

export type FooterLink = {
  label: string
  href?: string
  target?: '_blank'
  extraProps?: {
    [key: `data-${string}`]: unknown
  }
  onClick?: () => void
}

export const links: Record<string, FooterLink> = {
  gift: { label: TEXTS.GIFT, href: GIFT_CARD },
  blog: { label: TEXTS.BLOG, href: BLOG_URL },
  business: { label: TEXTS.BUSINESS, href: BUSINESS_PAGE_URL },
  careers: {
    label: TEXTS.CAREERS,
    href: CAREERS_URL,
    target: '_blank',
    extraProps: {
      'data-testid': 'careers-footer',
    },
  },
}

export const bottomLinks: Record<string, FooterLink> = {
  privacy: {
    label: TEXTS.PRIVACY,
    href: PRIVACY_POLICY_URL,
    extraProps: {
      'data-testid': 'privacy-footer',
    },
  },
  cookie: {
    label: TEXTS.COOKIE,
    href: COOKIE_POLICY_URL,
    extraProps: {
      'data-testid': 'cookie-footer',
    },
  },
  impressum: {
    label: TEXTS.IMPRESSUM,
    href: IMPRESSUM_URL,
    extraProps: {
      'data-testid': 'impressum-footer',
    },
  },
  terms: {
    label: TEXTS.TERMS,
    href: TERMS_OF_SERVICE_URL,
    extraProps: {
      'data-testid': 'terms-footer',
    },
  },
  accessibility: {
    label: TEXTS.ACCESSIBILITY,
    href: ACCESSIBILITY_URL,
    extraProps: {
      'data-testid': 'accessibility-footer',
    },
  },
  accessibilityButton: {
    label: TEXTS.ACCESSIBILITY_BUTTON,
    extraProps: { 'data-acsb-custom-trigger': 'true' },
  },
}

export const mediumBottomLinks: FooterLink[] = [
  bottomLinks.privacy,
  bottomLinks.cookie,
  bottomLinks.accessibility,
  bottomLinks.impressum,
]

export type FooterLinksSection = {
  label: string
  links: FooterLink[]
}

export const socialLinks: FooterLink[] = socials.map(({ name, href }) => ({
  label: name,
  href,
}))
